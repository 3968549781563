<template>
  <div>
    <div class="das_top_cont">
      <div class="history_box">
        <div class="accountright_cont_box">
          <div class="flex_center_between_box" style="">
            <h2 class="history_box-head">What type of problems you are reporting?</h2>

            <div style="display: flex">
              <button
                class="btn_blue flex_center btn_eee"
                v-if="Edit"
                @click="Edit = !Edit"
              >
                Edit
              </button>
              <div v-else style="display: flex">
                <button
                  class="btn_blue flex_center"
                  style="margin-right: 10px"
                  @click="Edit = !Edit"
                >
                Save
                </button>
                <button class="btn_blue flex_center" @click="Edit = !Edit">
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <div class="" style="width: 100%">
            <div class="information-content">
              <div class="sel_box computer_right_cont_box">
                <el-select
                  style="width: 100%"
                  v-model="IndicatorsValType"
                  placeholder=""
                  autocomplete="off"
                >
                  <el-option
                    v-for="(item, index) in type"
                    :key="index"
                    :label="
                      item.type == '1'
                        ? 'Online data'
                        : item.type == '2'
                        ? 'Offline data'
                        : item.type
                    "
                    :value="item.type"
                  ></el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="flex_center_between_box" style="">
            <h2 class="history_box-head">Tell us more about your problems</h2>
          </div>
          <div class="his_sel_box" style="width: 100%">
            <div class="information-content">
              <div class="sel_box computer_right_cont_box">
                <el-input
                autocomplete="off"
                  type="textarea"
                  :autosize="{ minRows: 5, maxRows: 8 }"
                  placeholder=""
                  v-model="textarea2"
                >
                </el-input>
              </div>
            </div>
          </div>
               <div class="flex_center_between_box" style="">
            <h2 class="history_box-head margin-bottom20">Would you like to provide a screenshot or a file regarding your problem?</h2>
          </div>
          <div class="his_sel_box" style="width: 100%">
            <div class="information-content">
              <div class="sel_box computer_right_cont_box">
                 <div style="display: flex;">
              <el-radio v-model="radio" label="1">YES</el-radio>
                <el-radio v-model="radio" label="0">No</el-radio>
          </div >
       
            </div>
          </div>
             <div class="attachment">Add files</div>
              </div>
          <div
            class="flex_center_between_box"
            style="margin-top: 40px;"
          >
            <h2 class="history_box-head">Fill in your contact information</h2>
          </div>
          <div class="his_sel_box">
            <div class="information-content" style="margin-bottom: 16px;">
              <div class="sel_box computer_right_cont_box">
                <el-input
                  class="inline-input"
                  clearable
                  v-model="CategoryVal"
                  autocomplete="off"
                  placeholder="First Name"
                ></el-input>
              </div>
            </div>
            <div class="information-content" style="margin-bottom: 16px;">
              <div class="sel_box computer_right_cont_box">
                <el-input
                  class="inline-input"
                  clearable
                  v-model="CategoryVal"
                  autocomplete="off"
                  placeholder="Last Name"
                ></el-input>
              </div>
            </div>
            <div class="information-content" style="margin-bottom: 16px;">
              <div class="sel_box computer_right_cont_box">
                <el-input
                  class="inline-input"
                  clearable
                  autocomplete="off"
                  v-model="CategoryVal"
                  placeholder="Phone Number"
                ></el-input>
              </div>
            </div>
            <div class="information-content" style="margin-bottom: 16px;">
              <div class="sel_box computer_right_cont_box">
                <el-input
                autocomplete="off"
                  class="inline-input"
                  clearable
                  v-model="CategoryVal"
                  placeholder="Email"
                ></el-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mixins1 } from "@/mixins/index";

export default {
  mixins: [mixins1],
  data() {
    return {
      value1: "",
      textarea2: "",
      CategoryVal: "",
      type: [],
      window: window,
      IndicatorsValType: "",
      Edit: true,
      radio:'0'
    };
  },
  beforeDestroy() {},
  destroyed() {},
  mounted() {},

  methods: {
    handleSelectionChange() {
      val;
    },
    apptablelist() {
      this.yourUsagelist.push(
        {
          UserAccount: "Ruling documents",
          Date: "2024-07-26  11:23:32",
          CompanyName: "Apple Inc.",
          UnitsUsed: "2",

          UsageType: "News Saction",
        },
        {
          UserAccount: "Ruling documents",
          Date: "2024-07-26  11:23:32",
          CompanyName: "Apple Inc.",
          UnitsUsed: "2",

          UsageType: "News Saction",
        },
        {
          UserAccount: "Ruling documents",
          Date: "2024-07-26  11:23:32",
          CompanyName: "Apple Inc.",
          UnitsUsed: "2",

          UsageType: "News Saction",
        },
        {
          UserAccount: "Ruling documents",
          Date: "2024-07-26  11:23:32",
          CompanyName: "Apple Inc.",
          UnitsUsed: "2",

          UsageType: "News Saction",
        }
      );
    },
  },
};
</script>
<style scoped>
.flex_center_between_box {
  display: flex;
  justify-content: space-between;
}
.form-email {
  display: flex;
  width: 100%;
}
.block-con-body-text {
  word-break: keep-all;
  text-align: justify;
  font-size: 1rem;
  color: #022955;
}
.das_top_cont {
  /* margin-top: 3.5714285714285716rem; */
}

.item_txtcolor1 {
  color: #1290c9;
}
.item_txtcolor2 {
  color: #55b419;
}
.item_txtcolor3 {
  color: #ffa000;
}
.das_top_item .color1 {
  background: rgba(18, 144, 201, 0.12);
}
.das_top_item .color2 {
  background: rgba(85, 180, 25, 0.12);
}
.das_top_item .color3 {
  background: rgba(255, 160, 0, 0.12);
}
.das_top_item .color4 {
  background: #ff7600;
}
.das_top_item .item_img img {
  width: 1.2857142857142858rem;
  height: 1.2857142857142858rem;
}
.das_top_item .item_title {
  font-size: 1.2857142857142858rem;
  color: #022955;
  margin: 0.7142857142857143rem 0 0.7142857142857143rem;
  text-align: left;
}
.das_top_item .item_group {
  margin-left: 0.7142857142857143rem;
  font-size: 1.2857142857142858rem;

  /* width: 17.142857142857142rem; */
}
.das_top_item .item_lab {
  display: block;
  color: #1a2332;

  margin-top: 0.35714285714285715rem;
}
.item_txt {
  font-size: 2.142857142857143rem;
  text-align: center;
}
.cont_m {
  margin-top: 2.142857142857143rem;
  margin-bottom: 5.714285714285714rem;
}

.country_box {
  margin-top: 4.285714285714286rem;
}

.country_box .title {
  margin-bottom: 0;
}

.title {
  margin-bottom: 1.4285714285714286rem;
}

.title_txt {
  font-size: 1.1428571428571428rem;
  font-family: 'DMSans Bold';
}

.title_txt img {
  width: 1.4285714285714286rem;
  margin-right: 0.5714285714285714rem;
}

.c_d_buttom {
  justify-content: flex-end;
  /* margin-top: 1.4285714285714286rem; */
  margin-bottom: 0;
}

.btn_blue {
  padding: 0.7142857142857143rem 1.4285714285714286rem;
  background: #1290c9;
  border-radius: 0.5714285714285714rem;
  font-size: 1.2857142857142858rem;
  color: #ffffff;
  cursor: pointer;
}

.btn_blu {
  margin-right: 1.4285714285714286rem;
}

.btn_blue img {
  width: 1.2857142857142858rem;
  height: 1.2857142857142858rem;
  margin-right: 0.42857142857142855rem;
}
.sel_box {
  margin-right: 1.7857142857142858rem;
  width: 35%;
  height: auto !important;
}
.sel_box /deep/ .el-input__inner {
  border-color: #ccc !important;
  border-radius: 0.5714285714285714rem;
  font-size: 1.4285714285714286rem;
}

.das_top_cont {
  /* margin-top: 3.5714285714285716rem; */
}

.das_top_item {
  width: 32.5%;
  padding: 1.4285714285714286rem;
  /* border-radius: 1rem; */
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0rem 0.14285714285714285rem 0.2857142857142857rem 0rem rgba(0, 0, 0, 0.12);
}

.das_top_item .item_img {
  width: 4.285714285714286rem;
  height: 4.285714285714286rem;
  line-height: 5.571428571428571rem;
  text-align: center;
  border-radius: 50%;
}

.das_top_item .item_title {
  font-size: 1.2857142857142858rem;
  color: #022955;
  margin: 0.7142857142857143rem 0 0.7142857142857143rem;
  text-align: center;
}

.top_tab {
  margin-top: 2.857142857142857rem;
}
/deep/ .el-table {
  margin-top: 2.142857142857143rem;
}
/deep/ .el-table th.el-table__cell {
  background-color: #f2f5f9 !important;
}
.jiange {
  color: #cccccc;
  margin: 0 1.4285714285714286rem;
  font-size: 1.4285714285714286rem;
  /* font-size: 1.25em; */
}
.contclass {
  font-size: 1.4285714285714286rem;
  color: #1290c9;
  font-style: normal;
}
/deep/ .el-table .cell {
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  /* line-height: 2.142857142857143rem; */
}
/deep/ .el-table th.el-table__cell > .cell {
  font-family: 'DMSans Bold';
}
.last-colmun {
  padding: 2rem 0rem;
  border-bottom: 0.07142857142857142rem solid #ebeef5;
}
.dingwei {
  position: absolute;
  top: -2.142857142857143rem;
  right: 0rem;
}
/deep/ .el-descriptions-item__cell {
  padding-bottom: 2.142857142857143rem !important;
}
/deep/ .avatar-uploader .el-upload {
  border: 0.07142857142857142rem dashed #d9d9d9;
  border-radius: 0.42857142857142855rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
  font-size: 1.4285714285714286rem;
  color: #8c939d;
  width: 5rem;
  height: 5rem;
  line-height: 5rem;
  text-align: center;
}
/deep/ .avatar {
  width: 5rem;
  height: 5rem;
  display: block;
}

/* 日历大小 */
.el-range-editor.el-input__inner {
  /* width: 16rem !important; */
  padding: 0.21428571428571427rem 0.21428571428571427rem;
}

.el-date-editor /deep/ .el-range__icon {
  font-size: 1.1428571428571428rem;
  margin-left: -0.35714285714285715rem;
  color: #1a2332;
  /* float: left; */
  line-height: 2.2857142857142856rem;
  position: relative;
  left: 47%;
}
.information-content {
  display: flex;
  margin-bottom: 2.142857142857143rem;
}
.btn_eee {
  background: #eee;
  color: #1a2332;
}
.history_box-head {
  margin-bottom: 0.7142857142857143rem;
  height: 2.142857142857143rem;
}
.accountright_cont_box .flex_center_between_box {
    margin-bottom: 0rem;
}
/deep/ .el-textarea__inner {
  border-radius: 0.5714285714285714rem !important;
  border: 0.07142857142857142rem solid #cccccc !important;
}
/deep/ .el-radio__label {
   font-size: 1.4285714285714286rem;
color: #1A2332;
}
.margin-bottom20 {
  margin-bottom: 1.4285714285714286rem;
}
.attachment{
font-size: 1.4285714285714286rem;
color: #1290C9;
line-height: 1.6428571428571428rem;
text-align: left;
font-style: normal;
text-decoration-line: underline;
margin-top: 2.142857142857143rem;
}
@media screen and (max-width: 1441px) {
  .btn_blue {
    font-size: 14px;
    width: max-content;
  }
}
</style>